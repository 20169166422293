<template>
  <ui-parts-form-success-box>
    <template #form="{ onSuccess }">
      <common-forms-forgot-password @success="onSuccess" />
    </template>
    <template #success>
      <common-auth-confirm-email-message />
    </template>
  </ui-parts-form-success-box>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'auth',
    middleware: 'sanctum:guest'
  });

  useSeoMeta({
    title: 'Forgot Password'
  });
</script>
