<template>
  <form
    class="grid mb-7 max-w-md w-full"
    @submit="onSubmit"
  >
    <ui-typography
      tag="h1"
      variant="h1"
      class="mb-2.5 lg:mb-12 text-center"
      text="Welcome"
    />
    <div class="grid gap-2.5 lg:gap-5 mb-5 lg:mb-8">
      <div class="grid gap-5 lg:gap-7">
        <div class="grid gap-2.5 lg:gap-5">
          <ui-fields-text
            name="email"
            is-required
            type="email"
            label="johndoe@mail.com"
          />
          <div>
            <ui-fields-password
              label="Enter Your Password"
              name="password"
            />
            <ui-button
              underline="always"
              class="mr-auto pl-8 text-xs lg:text-sm"
              variant="link"
              hide-space
              size="xs"
              :to="{ name: 'forgot-password' }"
              text="Forgot Your Password?"
            />
          </div>
        </div>
        <ui-button
          type="submit"
          full
          :loading="isSubmitting"
          text="Login"
        />
      </div>
      <ui-typography
        variant="caption"
        class="text-center"
        text="Or"
      />
      <ui-social-auth />
      <ui-fields-control
        name="remember"
        label="Remember me on this device"
        class="lg:justify-start justify-center"
      />
    </div>
    <ui-button
      underline="always"
      variant="link"
      hide-space
      :to="{ name: 'registration' }"
      text="Create an Account"
    />
  </form>
</template>

<script setup lang="ts">
  import { object } from 'yup';
  import { useForm } from 'vee-validate';

  const emit = defineEmits(['success']);
  const onLogin = useLogin();

  const initialValues = {
    email: '',
    password: ''
  };

  const validationFields = useValidationRules();

  const { handleSubmit, isSubmitting } = useForm({
    validationSchema: object({
      email: validationFields.validationEmail(),
      password: validationFields.password()
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(onLogin, initialValues, {
      onSuccess() {
        emit('success');
      }
    })
  );
</script>
