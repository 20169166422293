<template>
  <ui-parts-form-success-box>
    <template #form>
      <common-forms-registration @success="onSuccess" />
    </template>
  </ui-parts-form-success-box>
</template>

<script setup lang="ts">
  definePageMeta({
    title: 'Registration',
    layout: 'auth',
    middleware: 'sanctum:guest'
  });
  const { routerReplace } = useRouterLocale();
  const onSuccess = () => {
    routerReplace({ name: 'auth-unverified' });
  };
</script>
