<template>
  <form
    class="grid mb-7 max-w-md"
    @submit="onSubmit"
  >
    <ui-typography
      tag="h1"
      variant="h1"
      class="mb-2.5 lg:mb-12 text-center"
      text="Enter your email associated with your account!"
    />
    <div class="grid lg:gap-8 gap-2.5">
      <ui-fields-text
        name="email"
        is-required
        type="email"
        label="Enter Your Email *"
      />

      <ui-button
        type="submit"
        :loading="isSubmitting"
        full
        class="lg:mb-5"
        text="Send"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
  import { object } from 'yup';

  const emit = defineEmits(['success']);
  const { onForgotPassword } = useForgotPassword();

  const initialValues = {
    email: ''
  };

  const validationFields = useValidationRules();

  const { handleSubmit, isSubmitting } = useForm({
    validationSchema: object({
      email: validationFields.validationEmail()
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(({ email }) => onForgotPassword(email), initialValues, {
      onSuccess() {
        emit('success');
      }
    })
  );
</script>
