<template>
  <form
    class="grid gap-0 lg:gap-5 mb-7 max-w-md w-full"
    @submit="onSubmit"
  >
    <ui-typography
      tag="h1"
      variant="h1"
      class="mb-1 lg:mb-8 text-center"
      text="Reset password"
    />
    <ui-fields-password
      label="Enter New Password"
      autocomplete="reset-password"
      name="password"
    />
    <ui-fields-password
      label="Confirm Password"
      autocomplete="repeat-reset-password"
      name="password_confirmation"
    />
    <ui-button
      type="submit"
      full
      :loading="isSubmitting"
      class="mt-3 lg:mt-0 lg:mb-5"
      text="Send"
    />
  </form>
</template>

<script setup lang="ts">
  import { object } from 'yup';
  import { useForm } from 'vee-validate';
  const apiRoutes = useApiRoutes();
  const route = useRoute();
  const onSetLoginUser = useSetLoginUser();

  const initialValues = {
    password: '',
    password_confirmation: ''
  };

  const validationFields = useValidationRules();

  const { handleSubmit, isSubmitting } = useForm({
    validationSchema: object({
      password: validationFields.password(),
      password_confirmation: validationFields.passwordConfirm()
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(
      form => {
        return apiRoutes.auth.resetPassword({
          ...form,
          token: route.query.token as string,
          email: route.query.email as string
        });
      },
      initialValues,
      {
        onSuccess(data) {
          onSetLoginUser(data);
        }
      }
    )
  );
</script>
