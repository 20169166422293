<template>
  <form
    class="grid mb-7 max-w-md w-full"
    @submit="onSubmit"
  >
    <ui-typography
      tag="h1"
      variant="h1"
      class="mb-2.5 lg:mb-12 text-center"
      text="Create an Account"
    />
    <div class="grid gap-2.5 lg:gap-5 mb-5 lg:mb-8">
      <div class="grid gap-5 lg:gap-7">
        <div class="grid gap-2.5 lg:gap-5">
          <ui-fields-text
            name="email"
            is-required
            type="email"
            label="Enter Your Email"
          />

          <ui-fields-password
            label="Enter Your Password"
            name="password"
          />

          <ui-fields-password
            label="Confirm Password"
            name="password_confirmation"
          />
        </div>

        <ui-button
          full
          type="submit"
          :loading="isSubmitting"
          text="Sign Up for Free"
        />
      </div>
      <ui-typography
        variant="caption"
        class="text-center"
        text="Or"
      />
      <ui-social-auth />

      <ui-fields-control
        name="agreed_with_policies"
        :checked-value="true"
        :unchecked-value="false"
      >
        <template #label="{ inputId }">
          <ui-fields-label
            control
            :for="inputId"
          >
            <i18n-t
              keypath="By creating an account I agree to the {privacy}, {refund}, {terms}"
              scope="global"
              tag="span"
            >
              <template #privacy>
                <ui-button
                  hide-space
                  variant="link"
                  underline="always"
                  target="_blank"
                  href="/privacy-policy"
                >
                  {{ $t('Privacy Policy') }}
                </ui-button>
              </template>
              <template #refund>
                <ui-button
                  hide-space
                  variant="link"
                  underline="always"
                  target="_blank"
                  href="/refund-policy"
                >
                  {{ $t('Refund Policy') }}
                </ui-button>
              </template>
              <template #terms>
                <ui-button
                  hide-space
                  variant="link"
                  underline="always"
                  target="_blank"
                  href="/terms"
                >
                  {{ $t('Terms of Use Agreement') }}
                </ui-button>
              </template>
            </i18n-t>
          </ui-fields-label>
        </template>
      </ui-fields-control>
      <ui-fields-control
        name="remember"
        label="Remember me on this device"
        class="lg:justify-start justify-center"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
  import { object } from 'yup';
  import { useForm } from 'vee-validate';

  const emit = defineEmits(['success']);
  const apiRoutes = useApiRoutes();
  const { setUser } = useAuthUser();

  const initialValues = {
    email: '',
    password: '',
    password_confirmation: '',
    agreed_with_policies: false
  };

  const validationFields = useValidationRules();

  const { handleSubmit, isSubmitting } = useForm({
    validationSchema: object({
      email: validationFields.validationEmail(),
      password: validationFields.password(),
      password_confirmation: validationFields.passwordConfirm(),
      agreed_with_policies: validationFields.conditional(
        'Site cannot be used if user do not agree with Privacy and Cookie policies'
      )
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(apiRoutes.auth.registration, initialValues, {
      onSuccess(user) {
        setUser(user);
        emit('success');
      }
    })
  );
</script>
